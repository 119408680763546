import styled from "styled-components";
import media from "constants/media";

import { ButtonStyled } from "components/shared/button/Button.styled";

export const NewsletterStyled = styled.div`
    display: flex;
    width: 100%;

    background: var(--color-darkNight);
`;

export const Form = styled.form`
    width: 100%;
`;

export const Email = styled.div`
    display: flex;
    align-items: flex-start;

    ${ButtonStyled} {
        height: 43px;

        padding: 0 20px;

        ${media.mdUp`
            height: 48px;
        `}
    }
`;

export const ArrowIcon = styled.img`
    width: 17px;
`;

export const SuccessMessage = styled.div`
    flex-basis: 100%;
    width: 100%;
    margin-top: -20px;

    color: var(--color-green);

    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
`;
