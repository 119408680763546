import { useMediaQueryContext } from "lib/mediaQueryContext";
import Mobile from "./mobile/Mobile";
import Desktop from "./desktop/Desktop";

import { FooterStyled } from "./Footer.styled";

export default function Footer() {
    const { mobileView, desktopView } = useMediaQueryContext();

    return (
        <FooterStyled>
            {mobileView && <Mobile />}
            {desktopView && <Desktop />}
        </FooterStyled>
    );
}
