import styled from "styled-components";
import media from "constants/media";

export const ContainerStyled = styled.div`
    position: relative;

    width: 100%;

    margin: 0 auto;
    padding: 0 10px;

    ${media.mdUp`
        max-width: ${({ narrow }) => (narrow ? "980px" : "1200px")};
    `}
`;
