import styled, { css } from "styled-components";
import media from "constants/media";

export const InputStyled = styled.div`
    position: relative;

    width: 100%;
    height: 100%;

    margin-bottom: 28px;

    input[type="number"] {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -moz-appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        margin: 0;
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none;
    }
`;

export const LeftIcon = styled.div`
    position: absolute;
    top: ${({ top }) => (top ? `${top}%` : "70%")};
    left: 16px;
    z-index: 7;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;

    transform: translateY(-50%);

    img,
    svg {
        width: 15px;
        max-height: 15px;
    }
`;

export const Field = styled.input`
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;

    padding: 0 20px;

    color: var(--color-darkNight);
    font-size: 14px;
    line-height: 20px;

    border: 1px solid
        var(--color-${({ hasError }) => (hasError ? "red" : "lightGrey")});
    outline: 0;

    transition: 0.3s all ease-in-out;

    &::placeholder {
        color: var(--color-darkBlue);

        opacity: 0.5;
    }

    ${({ hasLeftIcon }) =>
        hasLeftIcon &&
        css`
            padding-left: 55px;
        `}

    ${({ hasRightIcon }) =>
        hasRightIcon &&
        css`
            padding-right: 55px;
        `}


    ${media.mdUp`
        font-size: 15px;
        height: 50px;
        line-height: 25px;
    `}

    &:focus {
        border: 1px solid var(--color-lightNight);
    }
`;

export const LabelText = styled.span`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
`;

export const HiddenLabelText = styled.span`
    position: absolute;
    left: 100%;

    width: 0;
    height: 0;

    overflow: hidden;

    opacity: 0;
`;

export const Label = styled.label`
    position: relative;

    display: block;

    button {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

export const RightIcon = styled.div`
    position: absolute;
    top: 71%;
    right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;

    transform: translateY(-50%);

    img,
    svg {
        width: 15px;
        max-height: 15px;
    }
`;

export const Error = styled.p`
    position: absolute;
    bottom: -4px;
    left: ${({ errorMessageWidth }) => (errorMessageWidth ? "50%" : "0")};

    width: ${({ errorMessageWidth }) => errorMessageWidth || "100%"};

    color: var(--color-red);
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;

    transform: translate(
        ${({ errorMessageWidth }) => (errorMessageWidth ? "-50% " : "0")},
        100%
    );
`;
