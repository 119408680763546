import styled, { css } from "styled-components";
import media from "constants/media";
import Button from "components/shared/button/Button";

export const MobileStyled = styled.div`
    position: relative;
    z-index: 2;

    height: 60px;

    background: var(--color-darkNight);

    ${media.mobileOnly}
`;

export const Floating = styled.div`
    position: ${({ isFloating }) => (isFloating ? "fixed" : "relative")};
    z-index: 10;

    width: calc(100% - 2px);

    background: var(
        --color-${({ isFloating }) => (isFloating ? "peach" : "darkNight")}
    );

    box-shadow: ${({ isFloating }) =>
        isFloating ? `0 0 0 2px var(--color-purple)` : "none"};

    transition: 0.3s all ease-in-out;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
`;

export const Open = styled.button`
    img {
        height: 17px;
    }
`;

export const LogoContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;

    height: 35px;

    transform: translate(-50%, -50%);
`;

export const Order = styled.a`
    color: var(--color-white);
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
`;

export const Menu = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;

    min-width: 270px;
    height: 100%;
    padding: 20px 0 0 20px;

    background: var(--color-peach);

    transform: translateX(-100%);
    opacity: 0;

    transition: 0.4s transform ease-in-out, 0.3s opacity ease-in-out;

    ${({ isOpen }) =>
        isOpen &&
        css`
            transform: translateX(0);
            opacity: 1;
        `}
`;

export const Items = styled.ul`
    list-style: none;
`;

export const Item = styled.li`
    &:not(:last-child) {
        margin-bottom: 15px;
    }

    &:last-child {
        padding-top: 5px;
    }
`;

export const Close = styled.button`
    width: 17px;

    svg g {
        fill: var(--color-white);
    }
`;

export const StyledLink = styled.a`
    padding-bottom: 4px;

    color: var(--color-white);
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;

    border-bottom: 1px solid transparent;

    transition: 0.2s border-color ease-in-out;

    &.active,
    &:hover,
    &:focus {
        border-bottom: 1px solid var(--color-white);
    }
`;

export const StyledButton = styled(Button)`
    padding: 8px;

    ${media.mdUp`
        padding: 8px;
    `}
`;
