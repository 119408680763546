import styled from "styled-components";
import media from "constants/media";
import IconToggle from "components/shared/svg/IconToggle";

import { NewsletterStyled } from "components/footer/newsletter/Newsletter.styled";

export const MobileStyled = styled.div`
    position: relative;

    padding-top: 20px;
    overflow: hidden;

    color: var(--color-white);

    background: var(--color-darkNight);

    ${NewsletterStyled} {
        margin-top: 14px;
    }

    ${media.mobileOnly}
`;

export const Toggle = styled.button`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 26px;
    height: 26px;
    margin: 0 auto;
`;

export const ToggleIcon = styled(IconToggle)`
    transform: ${({ isExpanded }) =>
        isExpanded ? "rotate(0deg)" : "rotate(180deg)"};

    transition: 0.3s all ease-in-out;
`;

export const Description = styled.p`
    padding-bottom: 20px;

    font-size: 12px;
    line-height: 20px;
    text-align: center;
`;
