import styled from "styled-components";
import media from "constants/media";

import { NewsletterStyled } from "components/footer/newsletter/Newsletter.styled";

export const DesktopStyled = styled.div`
    position: relative;
    z-index: 2;

    color: var(--color-white);

    background: var(--color-darkNight);

    ${media.desktopOnly}
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 3fr 3fr;
    width: 100%;
    padding: 60px 0;

    column-gap: 20px;
`;

export const LogoIcon = styled.img`
    display: block;
    width: 150px;

    margin-bottom: 14px;
`;

export const Copyright = styled.p`
    font-size: 12px;
    line-height: 20px;
`;

export const StyledLink = styled.a`
    display: block;

    color: var(--color-peach);
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;

    &:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const Title = styled.h2`
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
`;

export const Text = styled.p`
    font-size: 15px;
    line-height: 25px;
`;

export const Subscribe = styled.div`
    ${NewsletterStyled} {
        margin-top: 14px;
    }
`;

export const Purchasing = styled.div`
    position: relative;

    display: grid;
    grid-template-columns: 6fr 4fr 2fr;
    height: 145px;

    color: var(--color-darkNight);

    &:before {
        position: absolute;
        left: 0;

        width: 100vw;
        height: 100%;

        background: var(--color-white);

        transform: translateX(-100%);

        content: "";
    }
`;

export const Payment = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    background: var(--color-white);
`;

export const Label = styled.h2`
    margin-right: 20px;

    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
`;

export const Method = styled.img`
    margin-right: 20px;
`;

export const Delivery = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    background: var(--color-white);

    ${Text} {
        max-width: 154px;
    }
`;

export const List = styled.ul`
    list-style: none;
`;

export const ListItem = styled.li`
    margin: 0 0 10px;
    padding: 0;
`;
