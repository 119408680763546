import PropTypes from "prop-types";
import NextHead from "next/head";

function Head({ siteName, title, description, keywords }) {
    return (
        <NextHead>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="application-name" content={siteName} />
            <meta name="apple-mobile-web-app-title" content={siteName} />
            <meta name="description" content={description} />
            <meta property="og:site_name" content={siteName} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
        </NextHead>
    );
}

Head.propTypes = {
    siteName: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.array
};

Head.defaultProps = {
    siteName: "Lees! Cadeau",
    title: "Lees! Cadeau",
    description:
        "Wil jij iemand letterlijk verrassen? Verrassen met een romantisch verhaal, een hip tijdschrift of een spannend e-book? De keuze is geheel aan degene die jij verrast. Hierbij kan de ontvanger de kaart zowel online als in de winkels inwisselen!",
    keywords: ["lees", "cadeau"]
};

export default Head;
