import styled from "styled-components";
import media from "constants/media";

export const Content = styled.div`
    position: ${({ isFloating }) => (isFloating ? "fixed" : "relative")};
    z-index: 10;

    display: flex;
    width: 100%;
    height: ${({ isFloating }) => (isFloating ? 100 : 145)}px;
    margin: 0 auto;

    background: var(--color-darkNight);

    transition: 0.3s all ease-in-out;

    ${media.desktopOnly}
`;

export const Left = styled.div`
    display: flex;
    align-items: center;
    padding-right: 40px;
    padding-left: 20px;

    ${media.mdUp`
        padding-right: 90px;
    `}

    ${media.lgUp`
        padding-left: calc((100% - 1180px)/2);
    `}
`;

export const StyledLogo = styled.div`
    display: block;
    width: 100%;
    min-width: 110px;
`;

export const Right = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 20px 0 40px;

    background: var(--color-peach);

    ${media.mdUp`
        padding: 0 20px 0 90px;
    `}

    ${media.lgUp`
        padding-right: calc((100% - 1160px)/2);
    `}
`;

export const Navigation = styled.nav`
    width: 100%;
`;

export const Items = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    list-style: none;
`;

export const Item = styled.li`
    display: inline-flex;

    &:not(:last-child) {
        margin-right: 10px;
    }
`;

export const StyledLink = styled.a`
    padding-bottom: 4px;

    color: var(--color-white);

    border-bottom: 1px solid transparent;

    transition: 0.2s border-color ease-in-out;

    &.active,
    &:hover,
    &:focus {
        border-bottom: 1px solid var(--color-white);
    }
`;
