import styled from "styled-components";
import media from "constants/media";

export const HeaderStyled = styled.header`
    position: relative;
    z-index: 8;

    max-width: 100%;

    ${media.mdUp`
        height: 145px;
        background: var(--color-darkNight);
    `}
`;
