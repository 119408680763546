import PropTypes from "prop-types";

import { LayoutStyled } from "./Layout.styled";

function Layout({ children }) {
    return <LayoutStyled>{children}</LayoutStyled>;
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
