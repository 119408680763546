import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useMediaQueryContext } from "lib/mediaQueryContext";
import Mobile from "./mobile/Mobile";
import Desktop from "./desktop/Desktop";

import { HeaderStyled } from "./Header.styled";

export default function Header() {
    const { mobileView, desktopView } = useMediaQueryContext();
    const [isFloating, setIsFloating] = useState(false);
    const { ref, inView } = useInView({
        threshold: 1,
        initialInView: true
    });

    useEffect(() => {
        setIsFloating(!inView);
    }, [inView]);

    return (
        <HeaderStyled ref={ref}>
            {mobileView && <Mobile isFloating={isFloating} />}
            {desktopView && <Desktop isFloating={isFloating} />}
        </HeaderStyled>
    );
}
