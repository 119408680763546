import PropTypes from "prop-types";
import styled from "styled-components";

const Arrow = styled.div`
    position: relative;

    width: 14px;
    height: 7px;
`;

const Line = styled.span`
    position: absolute;
    bottom: 0;

    display: block;
    width: 10.5px;
    height: 2px;

    background-color: var(--color-white);

    :nth-of-type(1) {
        left: 0;

        transform: rotate(-38deg);
        transform-origin: left bottom;
    }

    :nth-of-type(2) {
        right: 0;

        transform: rotate(38deg);
        transform-origin: right bottom;
    }
`;

function IconToggle({ className, ...props }) {
    return (
        <Arrow className={className} {...props}>
            <Line />
            <Line />
        </Arrow>
    );
}

IconToggle.propTypes = {
    className: PropTypes.string
};

IconToggle.defaultProps = {
    className: ""
};

export default IconToggle;
