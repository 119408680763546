import Link from "next/link";
import Container from "components/shared/container/Container";

import Logo from "components/shared/svg/Logo";
import Newsletter from "components/footer/newsletter/Newsletter";
import {
    DesktopStyled,
    Content,
    Copyright,
    StyledLink,
    Title,
    Text,
    Subscribe,
    Purchasing,
    Payment,
    Label,
    Method,
    Delivery,
    List,
    ListItem
} from "./Desktop.styled";

function Desktop() {
    return (
        <DesktopStyled>
            <Container>
                <Content>
                    <div>
                        <Link href="/" passHref>
                            <a>
                                <Logo width={150} height={77} />
                            </a>
                        </Link>
                        <Copyright>
                            {`© www.leescadeaukaart.nl ${new Date().getFullYear()}`}
                        </Copyright>
                    </div>
                    <List>
                        <ListItem>
                            <Link
                                href="/verkooppunten"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Verkooppunten</StyledLink>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="/saldocheck" passHref prefetch={false}>
                                <StyledLink>Saldocheck</StyledLink>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                                href="/verdubbelaar"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Verdubbelaar</StyledLink>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="/zakelijk" passHref prefetch={false}>
                                <StyledLink>Zakelijk</StyledLink>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="/inwisselen" passHref prefetch={false}>
                                <StyledLink>Inwisselen</StyledLink>
                            </Link>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem>
                            <Link href="/acceptant" passHref prefetch={false}>
                                <StyledLink>Acceptant worden</StyledLink>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="/over-ons" passHref prefetch={false}>
                                <StyledLink>Over ons</StyledLink>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="/helpdesk" passHref prefetch={false}>
                                <StyledLink>Helpdesk</StyledLink>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                                href="/cookie-privacy"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Cookie & Privacy Policy</StyledLink>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                                href="/algemene-voorwaarden"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Algemene voorwaarden</StyledLink>
                            </Link>
                        </ListItem>
                    </List>
                    <div>
                        <Title>Vragen?</Title>
                        <Text>
                            Bel <a href="tel:0852086572">085 - 20 86 572</a>
                        </Text>
                        <Text>
                            Bereikbaar van maandag t/m vrijdag van 09.00 tot
                            17.00 uur
                        </Text>
                    </div>
                    <Subscribe>
                        <Title>Schrijf je in voor de nieuwsbrief</Title>
                        <Text>
                            Blijf op de hoogte van alle ontwikkelingen en acties
                        </Text>
                        <Newsletter />
                    </Subscribe>
                </Content>
            </Container>

            <Container>
                <Purchasing>
                    <Payment>
                        <Label>Betaalmogelijkheden</Label>
                        <Method
                            src="/images/logo-ideal.svg"
                            alt="iDEAL"
                            loading="lazy"
                            width={25}
                            height={22}
                        />
                        <Method
                            src="/images/logo-mastercard.svg"
                            alt="Mastercard"
                            loading="lazy"
                            width={22}
                            height={22}
                        />
                        <Method
                            src="/images/logo-visa.svg"
                            alt="Visa"
                            loading="lazy"
                            width={66}
                            height={22}
                        />
                    </Payment>
                    <Delivery>
                        <Method
                            src="/images/logo-postnl.svg"
                            alt="PostNL"
                            loading="lazy"
                            width={31}
                            height={30}
                        />
                        <Text>
                            Op werkdagen voor 17:00 besteld = vandaag verstuurd
                        </Text>
                    </Delivery>
                </Purchasing>
            </Container>
        </DesktopStyled>
    );
}

export default Desktop;
