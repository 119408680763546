import { forwardRef } from "react";
import PropTypes from "prop-types";

const Logo = forwardRef((props, ref) => {
    return (
        <svg
            ref={ref}
            viewBox="0 0 100 54.2"
            {...props}
            fill={props.fill || "#fff"}
        >
            <defs>
                <style>{`.cls-1{stroke-width:0}`}</style>
            </defs>
            <path
                className="cls-1"
                d="M87.4 29.5c0 5-3.9 7.2-10.1 7.2-3.4 0-6.9-.2-10.3-.6v-8h2.1c0 3.4 3.3 7.6 8.2 7.6s4.6-1.9 4.6-4.2c0-5.9-14.6-3.8-14.6-12.1s3.4-6.4 9.3-6.4 5.2.2 8.9.6v8h-2.1c0-3.4-1.9-7.6-6.8-7.6s-3.8.8-3.8 3.3c0 4.6 14.5 4.3 14.5 12.2ZM47 24.8v2.6c1.6-2.5 9.8-3.7 9.8-8.7s-1.3-4.8-4-4.8c-3.8 0-6 4.3-6 10.8Zm16.4 1c.2.7.3 1.5.3 2.3 0 5.5-5.2 8.7-11.6 8.7s-12.2-5.2-12.2-11.9S45.1 13 53 13s10.1 2.5 10.1 7.3c0 9.1-16.4 3.8-15.6 9.9.7 3.2 2.1 5 4.6 5.5 3.6.4 5.8-4.1 4.9-7.6l6.3-2.3Zm-43.5-1v2.6c1.6-2.5 9.8-3.7 9.8-8.7s-1.3-4.8-4-4.8c-3.8 0-6 4.3-6 10.8Zm16.3 1c.2.7.3 1.5.3 2.3 0 5.5-5.2 8.7-11.6 8.7s-12.2-5.2-12.2-11.9S17.9 13 25.8 13s10.1 2.5 10.1 7.3c0 9.1-16.4 3.8-15.6 9.9.7 3.2 2.1 5 4.6 5.5 3.6.4 5.8-4.1 4.9-7.6l6.3-2.3Zm-26.9 8c0 .9.9 1.8 1.8 1.8v1.1H2.7V4c0-.9-.9-1.8-1.8-1.8V1.1h8.4v32.7ZM89.1 2.2c.9 0 1.8.9 1.8 1.7v22.6h6.7V1.1h-8.5v1.1Zm1.7 27.7h6.8v6.8h-6.8v-6.8ZM66.9 47.6h1.3l-.6-3.4-.7 3.4zM40.4 47.6h1.4l-.7-3.4-.7 3.4zM75.8 47.6h1.4l-.7-3.4-.7 3.4zM24.3 43.4h-.7v5.7h.7c.3 0 .5-.1.6-.3.2-.4.3-1.2.3-2.6s0-2.2-.3-2.5c-.1-.2-.3-.3-.6-.3Z"
            />
            <path
                className="cls-1"
                d="M2.6 40.1v12.5h95V40.1h-95Zm3.8 8.8c.1.2.3.3.6.3s.5-.1.6-.3c.1-.2.2-.5.2-.8h1.3c0 .7-.3 1.3-.7 1.7-.4.3-.8.5-1.4.5s-1.2-.3-1.6-.7c-.5-.6-.6-1.5-.6-3.4s0-2.8.6-3.4c.3-.4.8-.7 1.6-.7s1 .2 1.4.5c.4.4.6 1 .6 1.7H7.7c0-.3 0-.6-.2-.8-.1-.2-.3-.3-.6-.3s-.5.1-.6.3c-.2.4-.3 1.2-.3 2.7s0 2.3.3 2.7Zm10.3 1.4-.3-1.6h-1.9l-.3 1.6h-1.3l2-8h1.2l1.9 8h-1.3Zm9.2-.7c-.3.4-.8.7-1.6.7h-2v-8h2c.8 0 1.2.3 1.6.7.5.6.6 1.5.6 3.3s0 2.8-.6 3.4Zm8.6-6.2h-2.1v2.3h1.8v1.1h-1.8v2.3h2.1v1.1h-3.4v-8h3.4v1.1Zm7.8 6.9-.3-1.6h-1.9l-.3 1.6h-1.3l2-8h1.2l1.9 8h-1.3Zm9.6-2c0 .6-.2 1.1-.6 1.5-.4.4-.9.6-1.5.6s-1.1-.2-1.5-.6c-.3-.4-.6-.9-.6-1.5v-6H49v6.1c0 .6.4.9.8.9s.8-.3.8-.9v-6.1h1.3v6Zm8 2L58.6 47l-.6 1.2v2.1h-1.3v-8H58v3.8l1.7-3.8H61l-1.6 3.3 1.9 4.7h-1.4Zm8.9 0-.3-1.6h-1.9l-.3 1.6H65l2-8h1.2l1.9 8h-1.3Zm8.9 0-.3-1.6h-1.9l-.3 1.6h-1.3l2-8h1.2l1.9 8h-1.3Zm8.6 0-1-3.3h-.7v3.3h-1.3v-8h2c1.5 0 2 .7 2 2.4s-.2 1.7-.9 2.1l1.2 3.6h-1.3Zm9.2-6.9h-1.3v6.9h-1.3v-6.9h-1.3v-1.1h3.9v1.1Z"
            />
            <path
                className="cls-1"
                d="M14.8 47.6h1.3l-.7-3.4-.6 3.4zM85.4 43.4h-.8v2.5h.8c.7 0 .8-.6.8-1.2s-.1-1.2-.8-1.2Z"
            />
        </svg>
    );
});

Logo.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    fill: PropTypes.string
};

Logo.defaultProps = {
    width: 100,
    height: 54.2,
    fill: "#fff"
};

Logo.displayName = "Logo";

export default Logo;
