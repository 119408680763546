import styled from "styled-components";

const Menu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 25px;
    height: 18px;
`;

const Line = styled.span`
    display: block;
    width: 19px;
    height: 2px;

    background-color: var(--color-white);

    :nth-of-type(2) {
        width: 22px;
    }

    :nth-of-type(3) {
        width: 25px;
    }
`;

function IconMenu() {
    return (
        <Menu>
            <Line />
            <Line />
            <Line />
        </Menu>
    );
}

export default IconMenu;
