import PropTypes from "prop-types";
import { useQuery } from "@apollo/client/react/hooks";
import Head from "components/shared/head/Head";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import Layout from "components/shared/layout/Layout";

import { GetPage } from "../../../helpers/api/cadeauConcepten/queries/pages.gql";

function PageLayout({
    siteName,
    title,
    description,
    keywords,
    slug,
    children
}) {
    let metadata;
    const { data, loading } = useQuery(GetPage, {
        variables: {
            slug
        },
        skip: !process.env.featureFlags.cmsData
    });

    if (!loading && data?.page?.metadata) {
        metadata = data?.page?.metadata;
    }

    return (
        <Layout>
            <Head
                siteName={metadata?.sitename || siteName}
                title={metadata?.title || title}
                description={metadata?.description || description}
                keywords={metadata?.keywords || keywords}
            />
            <Header />
            {children}
            <Footer />
        </Layout>
    );
}

PageLayout.propTypes = {
    slug: PropTypes.string.isRequired,
    siteName: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.array,
    children: PropTypes.node.isRequired
};

PageLayout.defaultProps = {
    siteName: "Lees! Cadeau",
    title: "Lees! Cadeau: boekenbon en tijdschriftenbon in 1 kaart",
    description:
        "De cadeaukaart voor een spannend boek, e-book of een hip tijdschrift. Zowel online als in de winkels inwisselbaar. Voor 17 uur besteld = vandaag verstuurd.",
    keywords: ["lees", "cadeau"]
};

export default PageLayout;
