import React, { Children } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import Link from "next/link";

// Active Link implementation from: https://github.com/zeit/next.js/tree/canary/examples/active-class-name

function ActiveLink({ children, activeClassName, exact, ...props }) {
    const { asPath, pathname } = useRouter();
    const child = Children.only(children);
    const childClassName = child.props.className || "";

    function isActiveRoute() {
        if (props.as) {
            if (exact) {
                return asPath === props.as;
            }

            return asPath.includes(props.as);
        }

        if (exact) {
            return pathname === props.href;
        }

        return pathname.includes(props.href);
    }

    const className = isActiveRoute()
        ? `${childClassName} ${activeClassName}`.trim()
        : childClassName;

    return (
        <Link {...props}>
            {React.cloneElement(child, {
                className: className || null
            })}
        </Link>
    );
}

ActiveLink.propTypes = {
    activeClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
    href: PropTypes.string.isRequired,
    as: PropTypes.string,
    exact: PropTypes.bool
};

ActiveLink.defaultProps = {
    activeClassName: "active",
    as: undefined,
    exact: false
};

export default ActiveLink;
