import dynamic from "next/dynamic";
import { useState } from "react";

import { MobileStyled, Toggle, ToggleIcon, Description } from "./Mobile.styled";

const Contents = dynamic(() => import("./contents/Contents"));

export default function Mobile() {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <MobileStyled>
            <Toggle
                onClick={() => setIsExpanded(!isExpanded)}
                aria-label={
                    isExpanded ? "Footer inklappen" : "Footer uitklappen"
                }
            >
                <ToggleIcon isExpanded={isExpanded} />
            </Toggle>
            {!isExpanded && (
                <Description>Over ons, helpdesk, voorwaarden</Description>
            )}
            {isExpanded && <Contents />}
        </MobileStyled>
    );
}
