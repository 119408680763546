import { useState } from "react";
import { useMutation } from "@apollo/client/react/hooks";
import { useForm } from "react-hook-form";
import colors from "constants/colors";

import Input from "components/shared/input/Input";
import Button from "components/shared/button/Button";
import IconCheck from "components/shared/svg/IconCheck";

import {
    NewsletterStyled,
    Form,
    Email,
    ArrowIcon,
    SuccessMessage
} from "./Newsletter.styled";

import { SubscribeToNewsletter } from "../../../helpers/api/cadeauConcepten/queries/newsletter.gql";

export default function Newsletter() {
    const { register, handleSubmit, reset, errors } = useForm();
    const [success, setSuccess] = useState(false);

    function showSuccess() {
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false);
            reset("email");
        }, 3000);
    }

    const [subscribe, { loading }] = useMutation(SubscribeToNewsletter, {
        onCompleted: data => {
            if (data) {
                showSuccess();
            }
        }
    });

    function onSubmit(data) {
        if (data?.email) {
            subscribe({
                variables: {
                    email: data.email
                }
            });
        }
    }

    return (
        <>
            <NewsletterStyled>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Email>
                        <Input
                            name="email"
                            type="email"
                            label="Vul hier je e-mailadres in"
                            hideLabelText
                            placeholder="Vul hier je e-mailadres in"
                            ref={register({
                                required: "E-mailadres is hier verplicht",
                                pattern: {
                                    value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                                    message:
                                        "Vul hier een geldig e-mailadres in"
                                }
                            })}
                            rightIcon={
                                success && <IconCheck fill={colors.green} />
                            }
                            errorMessage={errors?.email?.message}
                        />
                        <Button
                            type="submit"
                            aria-label="inschrijven"
                            disabled={loading}
                        >
                            <ArrowIcon
                                src="/images/icon-arrow-right-short-white.svg"
                                alt=""
                                width={17}
                                height={12.15}
                            />
                        </Button>
                    </Email>
                    {success && (
                        <SuccessMessage>
                            Je bent nu ingeschreven!
                        </SuccessMessage>
                    )}
                </Form>
            </NewsletterStyled>
        </>
    );
}
