import PropTypes from "prop-types";

import Link from "next/link";
import Button from "components/shared/button/Button";
import ActiveLink from "components/shared/activeLink/ActiveLink";

import Logo from "components/shared/svg/Logo";
import {
    Content,
    Left,
    Right,
    Navigation,
    Items,
    Item,
    StyledLink,
    StyledLogo
} from "./Desktop.styled";

function Desktop({ isFloating }) {
    return (
        <Content isFloating={isFloating}>
            <Left>
                <Link href="/" passHref>
                    <a aria-label="logo link homepage">
                        <StyledLogo>
                            <Logo width={109} height={56} />
                        </StyledLogo>
                    </a>
                </Link>
            </Left>
            <Right>
                <Navigation>
                    <Items>
                        <Item>
                            <ActiveLink
                                href="/verkooppunten"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Verkooppunten</StyledLink>
                            </ActiveLink>
                        </Item>
                        <Item>
                            <ActiveLink
                                href="/saldocheck"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Saldocheck</StyledLink>
                            </ActiveLink>
                        </Item>
                        <Item>
                            <ActiveLink
                                href="/verdubbelaar"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Verdubbelaar</StyledLink>
                            </ActiveLink>
                        </Item>
                        <Item>
                            <ActiveLink
                                href="/zakelijk"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Zakelijk</StyledLink>
                            </ActiveLink>
                        </Item>
                        <Item>
                            <ActiveLink
                                href="/inwisselen"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Inwisselen</StyledLink>
                            </ActiveLink>
                        </Item>
                        <Item>
                            <Link
                                href="/lees-cadeaukaart-bestellen"
                                passHref
                                prefetch={false}
                            >
                                <Button
                                    element="a"
                                    variant="secondary"
                                    withLetterSpacing
                                >
                                    Bestellen
                                </Button>
                            </Link>
                        </Item>
                    </Items>
                </Navigation>
            </Right>
        </Content>
    );
}

Desktop.propTypes = {
    isFloating: PropTypes.bool
};

Desktop.defaultProps = {
    isFloating: false
};

export default Desktop;
