import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";

import Link from "next/link";
import Container from "components/shared/container/Container";
import IconMenu from "components/shared/svg/IconMenu";

import Logo from "components/shared/svg/Logo";
import {
    MobileStyled,
    Floating,
    Content,
    Open,
    Menu,
    Items,
    Item,
    Close,
    StyledLink,
    StyledButton,
    LogoContainer
} from "./Mobile.styled";

const IconClose = dynamic(() => import("components/shared/svg/IconClose"));
const ActiveLink = dynamic(() =>
    import("components/shared/activeLink/ActiveLink")
);

function Mobile({ isFloating }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuOpened, setMenuOpened] = useState(false);
    const closeMenu = () => setMenuOpen(false);
    const router = useRouter();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        setMenuOpened(true);
    };

    useEffect(() => {
        router.events.on("routeChangeStart", closeMenu);

        return () => {
            router.events.off("routeChangeStart", closeMenu);
        };
    }, [router]);

    return (
        <MobileStyled>
            <Floating isFloating={isFloating}>
                <Container>
                    <Content>
                        <Open
                            type="button"
                            aria-label="Menu"
                            onClick={() => toggleMenu()}
                        >
                            <IconMenu />
                        </Open>
                        <Link href="/" passHref prefetch={false}>
                            <a aria-label="logo link homepage">
                                <LogoContainer>
                                    <Logo width={68} height={35} />
                                </LogoContainer>
                            </a>
                        </Link>
                        <Link
                            href="/lees-cadeaukaart-bestellen"
                            passHref
                            prefetch={false}
                        >
                            <StyledButton
                                variant={isFloating ? "secondary" : "primary"}
                            >
                                Bestellen
                            </StyledButton>
                        </Link>
                    </Content>
                </Container>
            </Floating>
            <Menu isOpen={menuOpen}>
                {menuOpened && (
                    <Items>
                        <Item>
                            <Close
                                type="button"
                                onClick={() => toggleMenu()}
                                aria-label="sluit menu"
                            >
                                <IconClose />
                            </Close>
                        </Item>
                        <Item>
                            <ActiveLink
                                href="/verkooppunten"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Verkooppunten</StyledLink>
                            </ActiveLink>
                        </Item>
                        <Item>
                            <ActiveLink
                                href="/saldocheck"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Saldocheck</StyledLink>
                            </ActiveLink>
                        </Item>
                        <Item>
                            <ActiveLink
                                href="/verdubbelaar"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Verdubbelaar</StyledLink>
                            </ActiveLink>
                        </Item>
                        <Item>
                            <ActiveLink
                                href="/zakelijk"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Zakelijk</StyledLink>
                            </ActiveLink>
                        </Item>
                        <Item>
                            <ActiveLink
                                href="/inwisselen"
                                passHref
                                prefetch={false}
                            >
                                <StyledLink>Inwisselen</StyledLink>
                            </ActiveLink>
                        </Item>
                        <Item>
                            <StyledButton variant="secondary" withLetterSpacing>
                                Bestellen
                            </StyledButton>
                        </Item>
                    </Items>
                )}
            </Menu>
        </MobileStyled>
    );
}

Mobile.propTypes = {
    isFloating: PropTypes.bool
};

Mobile.defaultProps = {
    isFloating: false
};

export default Mobile;
